import React from "react";

import DefaultLayout from "../layouts/DefaultLayout";
import ProjectSummary from "../sections/Project/ProjectSummary";
import ProjectGallery from "../sections/Project/ProjectGallery";
import ProjectSecondaryBanner from "../sections/Project/ProjectSecondaryBanner";
import ProjectTextBlock from "../sections/Project/ProjectTextBlock";
import NextProject from "../sections/Project/NextProject";
import ProjectFullWidthBanner from "../sections/Project/ProjectFullWidthBanner";
import ProjectSmallTextBlocks from "../sections/Project/ProjectSmallTextBlocks";
import ProjectTextInfo from "../sections/Project/ProjectTextInfo";
import ProjectBanner from "../sections/Project/ProjectBanner";
import ProjectFeature from "../sections/Project/ProjectFeature";
import ProjectMeta from "../sections/Project/ProjectMeta";
import DefaultHelmet from "../seo/DefaultHelmet";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const Project = ({pageContext}) => {
    const {project, nextProject} = pageContext;

    const getSection = ({fieldGroupName, ...section}, index) => {
        const key = index;

        switch (fieldGroupName) {
            case 'project_Acfproject_Sections_ProjectSummary': {
                return (
                    <ProjectSummary
                        key={key}
                        technologies={project.technologies?.nodes}
                        withContactUsButton
                        {...section}
                    />
                );
            }

            case 'project_Acfproject_Sections_ProjectGallery': {
                return (
                    <ProjectGallery
                        key={key}
                        projectTitle={project.title}
                        {...section}
                    />
                );
            }


            case 'project_Acfproject_Sections_ProjectSecondaryBanner': {
                return (
                    <ProjectSecondaryBanner
                        key={key}
                        projectTitle={project.title}
                        {...section}
                    />
                );
            }

            case 'project_Acfproject_Sections_ProjectSingleTextBlock': {
                return (
                    <ProjectTextBlock
                        key={key}
                        {...section}
                    />
                );
            }

            case 'project_Acfproject_Sections_ProjectFullWidthBanner': {
                return (
                    <ProjectFullWidthBanner
                        key={key}
                        {...section}
                    />
                );
            }

            case 'project_Acfproject_Sections_ProjectSmallTextBlocks': {
                return (
                    <ProjectSmallTextBlocks
                        key={key}
                        {...section}
                    />
                );
            }

            case 'project_Acfproject_Sections_ProjectTextInfo': {
                return (
                    <ProjectTextInfo
                        key={key}
                        {...section}
                    />
                );
            }

            case 'project_Acfproject_Sections_ProjectBanner': {
                return (
                    <div key={key}>
                        <ProjectBanner
                            projectTitle={section.showTitle && project.title}
                            {...section}
                            pageHeader={project.page_header}
                        />
                        <ProjectMeta type={"Projects"}/>
                    </div>
                );
            }

            case 'project_Acfproject_Sections_ProjectFeature': {
                return (
                    <ProjectFeature
                        key={key}
                        projectTitle={project.title}
                        {...section}
                    />
                );
            }

            default: {
                return null;
            }
        }
    };

    const getSections = () => {
        if (!Array.isArray(project.acfProject?.sections)) {
            return null;
        }
        return project.acfProject.sections.map(getSection);
    }

    const pageTitle = project?.acfProject?.metaData?.title || project.title;
    const pageDescription = project?.acfProject?.metaData?.description || `${project.title} by UTD: Experience innovation and transformative impact through exceptional design, development, marketing, and support.`;

    useBeaconWidget(beaconWidgetIds.GENERAL);

    return (
        <DefaultLayout preFooterSections={<NextProject {...nextProject} />}>
            <DefaultHelmet title={pageTitle} description={pageDescription} />

            {getSections()}
        </DefaultLayout>
    )
};

export default Project;
